<template>
  <v-dialog v-model="categoryDialog" persistent max-width="1000px">
    <v-card class="pa-0 pb-6" elevation="2">
      <v-card-text class="pb-0">
        <v-form v-model="valid" ref="form" lazy-validation>
          <v-container fluid>
            <v-row>
              <v-col cols="12" class="d-flex justify-end">    
                <v-btn color="red" class="ma-0" @click="handleCategoryDone">
                  {{ $vuetify.lang.t("$vuetify.shut") }}
                </v-btn>            
              </v-col>
            </v-row>
            <v-row class="pb-6">
              <v-col cols="12" class="pa-0">
                <span class="display-2">
                  <v-icon left class="pb-1">mdi-apple-finder</v-icon>
                  {{ $vuetify.lang.t("$vuetify.CreateCategory") }}
                </span>
              </v-col>
           
            </v-row>

            <v-row>
              <v-col cols="7">
                <v-sheet
                  height="300px"
                  style="border: 1px solid black; overflow-y: scroll"
                >
                  <v-list>
                    <v-list-item-group v-model="selectCategory">
                      <span
                        v-for="(item, key) in datas"
                        :key="key"
                        class="d-flex align-center justify-center"
                      >
                        <v-list-item class="ml-4 mr-2 my-2">
                          {{ item.name }}
                        </v-list-item>
                        <v-btn
                          color="red"
                          text
                          class="ml-2 mr-4"
                          @click="handleDeleteCategory(item)"
                        >
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                      </span>
                    </v-list-item-group>
                  </v-list>
                </v-sheet>
              </v-col>

              <v-col cols="5">
                <v-text-field
                  :label="$vuetify.lang.t('$vuetify.categoryName')"
                  v-model="categoryObj.name"
                  outlined
                  :rules="generalRule"
                />

                <v-textarea
                  :label="$vuetify.lang.t('$vuetify.note')"
                  v-model="categoryObj.note"
                  outlined
                />
              
                <span class="d-flex justify-end mt-4">  
                   <v-tooltip left>              
                      <template v-slot:activator="{ on, attrs }" >
                        <v-btn
                          color="green"
                          class="ma-0"
                          outlined
                          v-bind="attrs"
                          v-on="on"
                          @click="handleAddCategory"
                        >
                        <v-icon left>mdi-plus</v-icon>
                        {{ $vuetify.lang.t("$vuetify.save") }}
                      </v-btn>
                      </template>
                    <span class="d-flex justify-end">Please click save to confirm operation</span>
                  </v-tooltip>
                </span>
<!--                   
                <span class="d-flex justify-end mt-3">
                  <span class="headline red--text">
                    Please click save to confirm operation
                  </span>
                </span> -->
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      
    </v-card>

    <DeleteDialog
      :dialogDelete.sync="dialogDelete"
      :deleteObj="deleteObj"
      @handleDelete="handleDeleteConfirm"
    />
  </v-dialog>
</template>

<script>
import { dataTypesList, dataTypesDel, dataTypesUpsert } from "@/api/system";

const newCategory = () => {
  return {
    id: "",
    note: "",
    name: "",
    order: "",
  };
};

export default {
  name: "basicDataCategory",
  components: {
    DeleteDialog: () => import("@/components/deleteDialog"),
  },
  props: {
    myObj: Object,
    categoryDialog: Boolean,
    category: Array,
  },
  data() {
    return {
      valid: false,
      dialogDelete: false,
      generalRule: [(v) => !!v || "this field is required"],
      datas: [],
      selectCategory: "",
      categoryObj: newCategory(),
      deleteObj: {},
    };
  },
  methods: {
    handleCategoryDone() {
      this.$emit("update:categoryDialog", false);
      this.$emit("handleDone");
      this.categoryObj = newCategory();
      this.$refs.form.resetValidation();
    },
    handleAddCategory() {
      if (this.$refs.form.validate()) {
        dataTypesUpsert(this.categoryObj)
          .then(() => {
            this.getData();
            this.categoryObj = newCategory();
            this.$refs.form.resetValidation();
          })
          .catch((err) => console.log("Add Category Err", err));
      }
    },
    handleDeleteCategory(item) {
      this.dialogDelete = true;
      this.deleteObj = item;
    },
    getData() {
      dataTypesList()
        .then((res) => {
          this.datas = [...res.items];
        })
        .catch((err) => {
          console.log("Category Type Error", err);
        });
    },
    handleDeleteConfirm(item) {
      this.dialogDelete = false;

      dataTypesDel(item.id)
        .then(() => {
          this.getData();
        })
        .catch((err) => console.log("Delete Category Err", err));
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    selectCategory: function () {
      let temp = this.datas[this.selectCategory];

      if (temp != undefined) {
        this.categoryObj = temp;
      } else {
        this.categoryObj = newCategory();
        this.$refs.form.resetValidation();
      }
    },
  },
};
</script>